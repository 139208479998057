import { createStore } from 'vuex'
import { UserModule } from '@/store/modules/User'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    toasts: [],
    appName: process.env.VUE_APP_NAME,
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    showErrorToast(state, message) {
      let toast = { message: null, color: null }
      toast.message = message
      toast.color = 'danger'
      state.toasts.push(toast)
    },
    showWarningToast(state, message) {
      let toast = { message: null, color: null }
      toast.message = message
      toast.color = 'warning'
      state.toasts.push(toast)
    },
    showSuccessToast(state, message) {
      let toast = { message: null, color: null }
      toast.message = message
      toast.color = 'success'
      state.toasts.push(toast)
    },
  },
  actions: {},
  modules: {
    user: new UserModule(),
  },
})
