<template>
  <CHeader position="sticky" class="header mb-4">
    <CContainer fluid>
      <CHeaderToggler
        :class="[
          'px-md-0',
          'me-md-3',
          'd-lg-none',
          { 'prevent-hide': !sidebarVisible },
        ]"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none" to="/">
        <CIcon :icon="logoNegative" height="34" alt="Logo" />
      </CHeaderBrand>
      <!--      <CForm class="d-none d-md-flex">-->
      <!--        <CInputGroup>-->
      <!--          <CInputGroupText id="search-addon" class="bg-light border-0 px-1">-->
      <!--            <CIcon-->
      <!--              icon="cil-search"-->
      <!--              size="lg"-->
      <!--              class="my-1 mx-2 text-disabled"-->
      <!--            />-->
      <!--          </CInputGroupText>-->
      <!--          <CFormInput-->
      <!--            placeholder="Search..."-->
      <!--            ariaLabel="Search"-->
      <!--            ariaDescribedby="search-addon"-->
      <!--            class="bg-light border-0"-->
      <!--          />-->
      <!--        </CInputGroup>-->
      <!--      </CForm>-->
      <!--      <CHeaderNav class="d-none d-sm-flex ms-auto me-3">-->
      <!--        <AppHeaderDropdownNotif />-->
      <!--        <AppHeaderDropdownTasks />-->
      <!--        <AppHeaderDropdownMssgs />-->
      <!--      </CHeaderNav>-->
      <CHeaderNav class="ms-auto me-3">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
      <!--      <CHeaderToggler class="px-md-0 me-md-3">-->
      <!--        <CIcon-->
      <!--          icon="cil-applications-settings"-->
      <!--          size="lg"-->
      <!--          @click="$store.commit('toggleAside')"-->
      <!--        />-->
      <!--      </CHeaderToggler>-->
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
// import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
// import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
// import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logoNegative } from '@/assets/brand/logo-negative'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    // AppHeaderDropdownMssgs,
    // AppHeaderDropdownNotif,
    // AppHeaderDropdownTasks,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  background: var(--cui-primary);
}
</style>
