import { Identity } from '@/store/modules/User'
import jwtDecode from 'jwt-decode'

export function getIdentityFromJwt(token, refreshToken) {
  const jwt = jwtDecode(token)
  return new Identity(
    jwt.userId,
    token,
    refreshToken,
    jwt.exp,
    jwt.roles,
    jwt.companyId,
  )
}
