<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon icon="cil-menu" size="xxl" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <!--      <CDropdownHeader-->
      <!--        component="h6"-->
      <!--        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"-->
      <!--      >-->
      <!--        Account-->
      <!--      </CDropdownHeader>-->
      <!--      <CDropdownItem>-->
      <!--        <CIcon icon="cil-bell" /> Updates-->
      <!--        <CBadge color="info-gradient" class="ms-auto">{{ itemsCount }}</CBadge>-->
      <!--      </CDropdownItem>-->
      <!--      <CDropdownItem>-->
      <!--        <CIcon icon="cil-envelope-open" /> Messages-->
      <!--        <CBadge color="success-gradient" class="ms-auto">{{-->
      <!--          itemsCount-->
      <!--        }}</CBadge>-->
      <!--      </CDropdownItem>-->
      <!--      <CDropdownItem>-->
      <!--        <CIcon icon="cil-task" /> Tasks-->
      <!--        <CBadge color="danger-gradient" class="ms-auto">{{-->
      <!--          itemsCount-->
      <!--        }}</CBadge>-->
      <!--      </CDropdownItem>-->
      <!--      <CDropdownItem>-->
      <!--        <CIcon icon="cil-comment-square" /> Comments-->
      <!--        <CBadge color="warning-gradient" class="ms-auto">{{-->
      <!--          itemsCount-->
      <!--        }}</CBadge>-->
      <!--      </CDropdownItem>-->
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        Ustawienia
      </CDropdownHeader>
      <CDropdownItem class="pointer" @click="$router.push('/settings')">
        <CIcon icon="cil-settings" /> Ustawienia
      </CDropdownItem>
      <CDropdownItem class="pointer" @click="auth.logout()">
        <CIcon icon="cil-lock-locked" /> Wyloguj się
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { auth } from '@/main'

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      auth,
    }
  },
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
