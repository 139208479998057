import api from './api'
import { getIdentityFromJwt } from '@/utils/helpers'

export class Auth {
  static TOKEN_KEY = 'AUTH_TOKEN'
  static REFRESH_TOKEN_KEY = 'AUTH_REFRESH_TOKEN'
  static FORCE_SET_PASSWORD_KEY = 'FORCE_SET_PASSWORD'

  publicPages = ['Login', 'Reset hasła']
  defaultPartnerPageRouteName = 'Lista zamówień'
  defaultPageRouteName = 'Lista wycen'

  constructor(store, router) {
    this.store = store
    this.router = router
  }

  async init() {
    this.registerRouterGuard()
    try {
      await this.loginFromLocalStorage()
    } catch (error) {
      if (!error.response || 401 !== error.response.status) {
        console.error('Error in Auth.init():', error)
      }

      this.askForLogout(error)
    }
  }

  registerRouterGuard() {
    this.router.beforeEach((to, from, next) => {
      // const token = to?.query?.token
      // if (token) {
      //   this.loginWithToken(token, to)
      // }

      const authRequired = !this.publicPages.includes(to.name)
      const loggedIn = !!this.store.state.user.identity.token
      const forceSetPassword =
        localStorage.getItem(Auth.FORCE_SET_PASSWORD_KEY) === 'true'

      if ('Login' !== to.name && authRequired && !loggedIn) {
        next({
          name: 'Login',
          query: {
            redirectTo: to.fullPath,
          },
        })
      } else {
        if (forceSetPassword && 'SetPassword' !== to.name) {
          next({
            name: 'SetPassword',
            query: {
              redirectTo: to.fullPath,
            },
          })
        } else if ('/' === to.path || !this.isAllowed(to)) {
          next({
            name: this.defaultRouteName(this.store.state.user.identity.roles),
          })
        } else {
          next()
        }
      }
    })
  }

  async loginWithCredentials(username, password) {
    const response = await api.post('/api/auth/login', { username, password })
    this.login(response.data.token, response.data.refreshToken)
    this.redirectToDefaultPage()
    return response
  }

  loginWithToken(token) {
    return api.post('/api/auto-login', { token: token })
  }

  async loginFromLocalStorage() {
    let token = localStorage.getItem(Auth.TOKEN_KEY)
    let refreshToken = localStorage.getItem(Auth.REFRESH_TOKEN_KEY)

    if (!token || !refreshToken) {
      return
    }

    const identity = getIdentityFromJwt(token, refreshToken)

    let time = new Date().getTime()
    let expirationTime = this.getExpirationTime(identity.expires)

    if (time >= expirationTime) {
      let data

      try {
        data = await this.refresh(refreshToken)
      } catch (error) {
        if (!error.response || 401 !== error.response.status) {
          console.error('Error in Auth.init():', error)
        }

        this.askForLogout(error)

        return
      }

      token = data.token
      refreshToken = data.refreshToken
    }

    if (token && refreshToken) {
      this.login(token, refreshToken)
    }
  }

  login(token, refreshToken) {
    const identity = getIdentityFromJwt(token, refreshToken)

    clearTimeout(this.refreshTimeout)

    const time = new Date().getTime()
    const expirationTime = this.getExpirationTime(identity.expires)
    let timeout = expirationTime - time

    if (timeout <= 0) {
      timeout = 60000
    }

    this.refreshTimeout = setTimeout(async () => {
      let data

      try {
        data = await this.refresh(localStorage.getItem(Auth.REFRESH_TOKEN_KEY))
      } catch (error) {
        console.warn('Calling askForLogout() due to an error.')
        this.askForLogout()
        return
      }

      this.login(data.token, data.refreshToken)
    }, timeout)

    api.defaults.headers.common.Authorization = `Bearer ${identity.token}`

    localStorage.setItem(Auth.TOKEN_KEY, token)
    localStorage.setItem(Auth.REFRESH_TOKEN_KEY, refreshToken)

    this.store.commit('user/login', identity)
  }

  askForLogout(error) {
    let msg

    if (error && error.response && 401 === error.response.status) {
      msg =
        'Wygląda na to, że obydwa tokeny wygasły - musisz się zalogować ponownie. ' +
        'Czy mam Cię teraz przekierować na stronę logowania?'
    } else {
      msg =
        'Wystąpił błąd podczas próby przedłużenia sesji. ' +
        'Czy mam Cię teraz przekierować na stronę logowania?'
    }

    if (confirm(msg)) {
      this.logout()
    }
  }

  logout() {
    clearTimeout(this.refreshTimeout)
    delete api.defaults.headers.common.Authorization
    localStorage.removeItem(Auth.TOKEN_KEY)
    localStorage.removeItem(Auth.REFRESH_TOKEN_KEY)
    this.router.push({ name: 'Login' }).catch((error) => {
      console.error('Error during redirection:', error)
    })
  }

  async refresh(refreshToken) {
    const response = await api.post('/api/auth/refresh', { refreshToken })

    return {
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    }
  }

  redirectToDefaultPage() {
    const redirectTo = this.router.currentRoute.value.query.redirectTo

    if (redirectTo) {
      this.router.push(redirectTo).catch((error) => {
        console.error('Error during redirection:', error)
      })
    } else {
      this.router
        .push({
          name: this.defaultRouteName(this.store.state.user.identity.roles),
        })
        .catch((error) => {
          console.error('Error during redirection:', error)
        })
    }
  }

  getExpirationTime(expires) {
    return new Date(expires * 1000 - 60000).getTime()
  }

  isAllowed(route) {
    return (
      !route.meta.roles ||
      this.store.state.user.identity.roles.some((r) =>
        route.meta.roles.includes(r),
      )
    )
  }

  defaultRouteName(roles) {
    if (roles.includes('ROLE_PARTNER')) {
      return this.defaultPartnerPageRouteName
    }

    return this.defaultPageRouteName
  }
}
