import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

import { Auth } from '@/services/Auth'
import DropZone from 'dropzone-vue'

import 'dropzone-vue/dist/dropzone-vue.common.css'
//import * as Sentry from '@sentry/vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(DropZone)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

// Sentry.init({
//   app,
//   dsn: 'https://70441b770d0c4f2395a0baf1182f01ed@sentry.isedo.pl/7',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         /^https:\/\/api\.wastemaster\.pl/,
//         /^https:\/\/partner\.wastemaster\.pl/,
//       ],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//   ],
//   tracesSampleRate: 0.2,
// })

let auth
;(async () => {
  auth = new Auth(store, router)

  await auth.init()
})()

export { auth }

app.mount('#app')
