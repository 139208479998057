<template>
  <CSidebar
    class="bg-dark-gradient"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="d-none d-md-flex">
      <img src="logo.webp" alt="logo" :width="sidebarUnfoldable ? 40 : 70" />
      <CSidebarToggler @click="$store.commit('toggleUnfoldable')" />
    </CSidebarBrand>
    <AppSidebarNav :stats="stats" :key="statsKey" />
    <CWidgetStatsC
      v-if="supervisor && !sidebarUnfoldable"
      class="m-2"
      inverse
      color="success"
      :progress="{ value: 100 }"
    >
      <template #value>
        <div class="fs-6">Twój opiekun</div>
        {{ supervisor.name }} {{ supervisor.surname }} </template
      ><template #title>
        {{ supervisor.email }} <br />
        Telefon: {{ supervisor.phone }}
      </template>
      <template #icon><CIcon icon="cil-people" height="36" /></template>
    </CWidgetStatsC>
  </CSidebar>
</template>

<script>
import { ref, computed, toRaw } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import api from '@/services/api'
import store from '@/store'
import { Auth } from '@/services/Auth'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    const supervisor = ref(null)
    const stats = ref({})
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      supervisor,
      stats,
      statsKey: 0,
      loadedStats: true,
    }
  },
  mounted() {
    this.companyId = store.state.user.identity.companyId
    this.loadCompanyData()
    this.loadStats()
    setInterval(() => {
      if (!this.loadedStats || !localStorage.getItem(Auth.TOKEN_KEY)) {
        return
      }
      this.loadStats()
    }, 30000)
  },
  methods: {
    loadCompanyData() {
      api.get(`/api/companies/${this.companyId}`).then((response) => {
        if (response.data.supervisor) {
          this.supervisor = response.data.supervisor
        }
      })
    },
    loadStats() {
      this.loadedStats = false
      api
        .get(`/api/partner/statistics/count`)
        .then((res) => {
          if (
            Object.values(toRaw(this.stats)).reduce((a, b) => a + b, 0) ===
            Object.values(res.data).reduce((a, b) => a + b, 0)
          ) {
            return
          }
          this.stats = res.data
          this.statsKey = Math.random()
        })
        .finally(() => {
          this.loadedStats = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.supervisor {
  &-header {
    font-size: 1rem;
    font-weight: bold;
  }
  &-info {
    font-size: 0.75rem;
  }
}
</style>
